import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useContext, useEffect, useState } from "react";
import { listarPedidos } from "services/clientes";
import { AuthContext } from "context";
import { ArrayHelper } from "helpers/array";

export default function data() {
  const authContext = useContext(AuthContext);
  const { profile } = authContext;
  const [values, setValues] = useState([]);
  const [fullReturn, setFullReturn] = useState([]);

  const gerarListaDistribuidora = (lista) =>{
    if(!lista){
      return []
    }

    const distribuidora = ArrayHelper.uniques(lista, 'uid');
    const novaLista = []
    distribuidora.map((dist)=>{
      novaLista.push({
        ...dist.distribuidora,
        uid: dist.uid
      })
    })
    return novaLista
  }

  const gerarListaDados = (body, distribuidoras) => {
    const novaLista = [];

    distribuidoras.map((dist)=>{
      const registros = body.filter(item=> (item.uid === dist.uid));
      const telas = registros.map(item => (item.telas.map(tela=> parseInt(tela.qtd, 10)).reduce((acc, current) => acc + current, 0))).reduce((acc, current) => acc + current, 0)
      const telasWeFix = registros.map(item => parseInt(item.qtdTelasWeFix, 10)).reduce((acc, current) => acc + current, 0)
      
      novaLista.push({
        ...dist,
        telas,
        telasWeFix
      })
    })

    return novaLista;
  }

  useEffect(()=>{
    async function fecthData () {
      try{
        const body = await listarPedidos();
        const listaDistribuidoras = gerarListaDistribuidora(body);
        const res = gerarListaDados(body, listaDistribuidoras);
        const response = res ? res.map((item)=> ({
          author: { name: item.nome },
          function: { title: item.cnpj },
          contract: { qtdTelas: item.telas, qtdTelasWeFix: item.telasWeFix },
        })) : [];
        setValues(response);
        const listaClientes = body ? body.map(dadosCliente => {
          const resultadoTelas = dadosCliente.telas.reduce((acc, tela, index) => {
            acc[`tela_${index + 1}_qtd`] = tela.qtd;
            acc[`tela_${index + 1}_modelo`] = tela.modelo;
            return acc;
          }, {});

          return{
            id: dadosCliente.id,
            cnpjDistriuidora: dadosCliente.distribuidora.cnpj,
            emailDistriuidora: dadosCliente.distribuidora.email,
            nomeDistriuidora: dadosCliente.distribuidora.nome,
            telefoneDistriuidora: dadosCliente.distribuidora.telefone,
            nomeCliente: dadosCliente.nome,
            telefoneCliente: dadosCliente.telefone,
            qtdTelasWeFix: parseInt(dadosCliente.qtdTelasWeFix,10) || 0,
            qtdTelasTotais: dadosCliente.telas.map(tela=> parseInt(tela.qtd, 10)).reduce((acc, current) => acc + current, 0) || 0,
            numPedido: dadosCliente.numPedido,
            ...resultadoTelas,
            status: dadosCliente.status
          }
        }) : []

        setFullReturn(listaClientes)
      } catch(error){
        console.log(error)
      }
    }
    
    if(profile.admin){
      fecthData();
    }
  },[profile.admin])

  const getValueWithPercentage = (valueTotal, valueSpecific) => {
    if(valueTotal === 0 ){
      return '0% (0)'
    }
    const result = (valueSpecific / valueTotal) * 100;
    return `${result.toFixed(2)}% (${valueSpecific})`
  }


  return {
    rows: values.map((item)=>({
      cnpj: <Cnpj title={item.function.title} />,
      razao: <RazaoSocial name={item.author.name}/>,
      urna: <ItemTelas qtd={item.contract.qtdTelas} />,
      telaswefix: <ItemTelas qtd={getValueWithPercentage(item.contract.qtdTelas, item.contract.qtdTelasWeFix)} />
    })),
    fullReturn
  };
}


const RazaoSocial = ({ name }) => (
  <MDBox display="flex" alignItems="left" lineHeight={1}>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
    </MDBox>
  </MDBox>
);

const Cnpj = ({ title }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  </MDBox>
);

const ItemTelas = ({ qtd }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {qtd}
    </MDTypography>
  </MDBox>
);